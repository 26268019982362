<template>
  <v-card class="workspace-api-settings" elevation="0" rounded="lg" :disabled="disabledApi">
    <v-card-title>
      <h2 class="headline font-weight-medium mb-0">
        {{ workspace.title }}
      </h2>
    </v-card-title>

    <v-card-text>
      <v-row v-if="workspaceApiSettings" align="center" justify="start">
        <v-col class="col-12 col-md-6">
          <SDatePicker v-model="date" :label="`${$t('api.token_expiration')}`" />
        </v-col>

        <v-col class="col-12 col-md-4">
          <v-btn color="primary" :loading="creationConfirmed" x-large block @click="onCreateToken">
            {{ $t('api.generate_key') }}
          </v-btn>
        </v-col>

        <v-expand-transition>
          <v-col v-if="hasWorkspaceApiKey" cols="12">
            <v-text-field
              :value="workspaceApiKey"
              color="primary"
              label="Api key"
              background-color="#F1F5FB"
              hide-details
              outlined
              readonly
            >
              <template v-slot:append>
                <v-hover v-slot="{ hover }">
                  <v-btn icon @click="onCopyToken">
                    <v-icon :color="hover ? 'primary' : ''"> mdi-content-copy </v-icon>
                  </v-btn>
                </v-hover>
              </template>
              <template v-slot:append-outer>
                <v-hover v-slot="{ hover }">
                  <v-btn icon :loading="deletionConfirmed" @click="onDeleteToken">
                    <v-icon :color="hover ? 'primary' : ''">mdi-delete</v-icon>
                  </v-btn>
                </v-hover>
              </template>
            </v-text-field>
          </v-col>
        </v-expand-transition>
      </v-row>

      <v-row v-else-if="disabledApi">
        <v-col class="col-12">
          <h3 class="subtitle-2 font-weight-medium">
            {{ $t('api.errors.disabled') }}
          </h3>
        </v-col>
      </v-row>

      <v-row v-else align="center" justify="start">
        <v-col class="col-12">
          <v-skeleton-loader :boilerplate="true" type="article"></v-skeleton-loader>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { getFormattedDate } from '@/common/reusable/dateFunctions';
import SDatePicker from '@/common/components/SDatePicker';

export default {
  name: 'WorkspaceApiSettings',
  components: {
    SDatePicker,
  },
  props: {
    workspace: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      show: false,
      workspaceApiSettings: null,
      date: new Date().setFullYear(new Date().getFullYear() + 1),
      creationConfirmed: false,
      deletionConfirmed: false,
      disabledApi: false,
    };
  },
  computed: {
    ...mapGetters({
      profile: 'profile',
      userPassword: 'apiDoc/userPassword',
    }),
    workspaceApiKey() {
      return (
        this.workspaceApiSettings?.tokens?.find(
          (token) => `${token.user_id}` === `${this.profile.id}`,
        )?.token || []
      );
    },
    hasWorkspaceApiKey() {
      return !!this.workspaceApiKey;
    },
  },
  watch: {
    show: {
      handler: function (value) {
        if (!value) {
          this.workspaceApiSettings = null;
        }
      },
    },
    userPassword: {
      handler: function (value) {
        if (null === value) {
          this.workspaceApiSettings = null;
        }
      },
    },
  },
  async mounted() {
    this.loadWorkspaceApiSettings()
      .then((resp) => {
        this.workspaceApiSettings = resp;
        this.show = true;
      })
      .catch((err) => {
        if (404 === err.code) {
          this.disabledApi = true;
        } else {
          this.$notification.error(this.$t('general.error'));
          this.show = false;
        }
      });
  },
  methods: {
    ...mapActions({
      createWorkspaceApiKey: 'apiDoc/createWorkspaceApiKey',
      deleteWorkspaceApiKey: 'apiDoc/deleteWorkspaceApiKey',
      fetchWorkspaceApiToken: 'apiDoc/fetchWorkspaceApiToken',
      fetchWorkspaceApiSettings: 'apiDoc/fetchWorkspaceApiSettings',
    }),
    onCreateToken() {
      this.creationConfirmed = true;

      this.createWorkspaceApiKey({
        workspace_id: this.workspace?.id,
        expiration: getFormattedDate({
          date: this.date,
          format: 'YYYY-MM-DD',
        }),
      })
        .then(async () => {
          this.workspaceApiSettings = await this.loadWorkspaceApiSettings();
        })
        .catch(() => {
          this.$notification.error(this.$t('api.errors.not_created'));
        })
        .finally(() => {
          this.creationConfirmed = false;
        });
    },
    onCopyToken() {
      navigator.clipboard.writeText(this.workspaceApiKey);

      this.$notification.success(this.$t('api.token.copy'));
    },
    onDeleteToken() {
      this.deletionConfirmed = true;

      this.deleteWorkspaceApiKey({
        workspace_id: this.workspace?.id,
        user_id: this.profile?.id,
      })
        .then(async () => {
          this.workspaceApiSettings = await this.loadWorkspaceApiSettings();
        })
        .catch(() => {
          this.$notification.error(this.$t('api.errors.not_removed'));
        })
        .finally(() => {
          this.deletionConfirmed = false;
        });
    },
    async loadWorkspaceApiSettings() {
      return this.fetchWorkspaceApiToken(this.workspace?.id);
    },
  },
};
</script>

<style lang="scss" scoped>
.workspace-api-settings {
  border: 1px solid #dbe6f7;
}
</style>
